/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EmployeeProfileCreate } from './EmployeeProfileCreate';
import type { IssuedCertificationCreate } from './IssuedCertificationCreate';
import type { IssuedLicenseCreate } from './IssuedLicenseCreate';
import type { OfficerBannedSiteCreate } from './OfficerBannedSiteCreate';
import type { OfficerCategory } from './OfficerCategory';
import type { OfficerStatus } from './OfficerStatus';
import type { OfficerType } from './OfficerType';
import type { PostalAddress } from './PostalAddress';
import type { PreferredScheduleCreate } from './PreferredScheduleCreate';
import type { RateOverrideCreateNewOfficer } from './RateOverrideCreateNewOfficer';

export type OfficerCreate = {
    name?: string;
    id?: string;
    given_name: string;
    middle_name?: string;
    family_name: string;
    nickname?: string;
    address?: string;
    postal_address?: PostalAddress;
    image_url?: string;
    phone_number?: string;
    hire_date?: string;
    date_of_birth?: string;
    email?: string;
    emergency_contact_name?: string;
    emergency_contact_phone_number?: string;
    employee_id?: string;
    employee_type?: OfficerCreate.employee_type;
    is_terminated?: boolean;
    termination_date?: string;
    termination_reason?: string;
    status: OfficerStatus;
    category: OfficerCategory;
    type?: OfficerType;
    default_pay_rate: number;
    bill_rate?: number;
    sms_delivery_failure?: boolean;
    role_id: string;
    always_respect_officer_pay_rate?: boolean;
    exempt_employee?: boolean;
    salaried?: boolean;
    payroll_primary_workplace_customer_id?: (string | null);
    payroll_primary_workplace_non_customer_id?: (string | null);
    checkhq_id?: string;
    checkhq_contractor_id?: string;
    is_payroll_disabled?: boolean;
    job_title_id?: (string | null);
    workers_comp_code?: string;
    salary_weekly_hours?: number;
    deprecated_name?: string;
    preferredschedule?: Array<PreferredScheduleCreate>;
    certifications_create?: Array<IssuedCertificationCreate>;
    licenses_create?: Array<IssuedLicenseCreate>;
    banned_customers?: Array<OfficerBannedSiteCreate>;
    region_ids?: Array<string>;
    shift_rate_overrides?: Array<RateOverrideCreateNewOfficer>;
    onboarding_template_id?: string;
    supervisor_site_ids?: Array<string>;
    autogenerate_onboarding_link?: boolean;
    send_payroll_sms_onboard_link?: boolean;
    send_payroll_email_onboard_link?: boolean;
    reimbursement_policy_ids?: Array<string>;
    time_off_policy_ids?: Array<string>;
    pay_group_id?: string;
    profile_in?: EmployeeProfileCreate;
};

export namespace OfficerCreate {

    export enum employee_type {
        SCHEDULABLE = 'Schedulable',
        NOT_SCHEDULABLE = 'Not Schedulable',
    }


}

