import useSWR from 'swr'
import {
    OfficerCreate,
    OfficerService,
    OfficerUpdate,
    Officer,
    HighLevelOfficer,
    IssuedCertification,
    IssuedCertificationCreate,
    IssuedCertificationUpdate,
    TerminateOfficer,
    TerminateOfficerOffCycleFinalPayroll,
    IssuedLicense,
    IssuedLicenseCreate,
    IssuedLicenseUpdate,
    ApiError,
    File,
    FileCreate,
    FileUpdate,
    HighLevelOfficerWithBansAndRegions,
    HighLevelOfficerWithQualifications,
    HighLevelOfficerForEmployeePortal,
    OfficerCategory,
    OnboardingDocumentWithEmployeeAction,
    HighLevelOfficerWithOnboardingStatus,
    ReimbursementPolicy,
    OfficerWithPrimaryWorkplace,
} from '../generated'
import { FunctionStatusType } from './sharedTypes'
import { ParsedUrlQuery } from 'querystring'
import { errorReasonToString } from '../utils/errorUtils'
import { toast } from 'react-toastify'

import { OfficerWorkHistory } from '../generated/models/OfficerWorkHistory'
import { officerCollisionToastError } from '../components/Officer/Contact/collisionToast'
import { ReimbursementPolicyUpdateForOfficer } from '../generated/models/ReimbursementPolicyUpdateForOfficer'
import { OfficerEarliestTerminationDate } from '../generated/models/OfficerEarliestTerminationDate'
import { useMemo } from 'react'
import { isOfficerValidForSiteAndRegion } from 'src/utils/officerUtils'

export const validateOfficerId = (query: ParsedUrlQuery): string => {
    const { id } = query
    if (typeof id === 'string') {
        return id
    } else {
        return '' // TODO: @atulenko - improve error handling in UI layer
    }
}

type LoadSupervisorsType = {
    supervisors: HighLevelOfficerWithBansAndRegions[]
} & FunctionStatusType

type LoadOfficersType = {
    officers: HighLevelOfficer[]
} & FunctionStatusType

type LoadOfficersIncBansType = {
    officers: HighLevelOfficerWithBansAndRegions[]
} & FunctionStatusType

type LoadOfficerWithOnboardingStatusType = {
    officer: HighLevelOfficerForEmployeePortal | undefined
} & FunctionStatusType

type LoadOfficersWithOnboardingType = {
    onboarding: HighLevelOfficerWithOnboardingStatus[] | undefined
    onboarded: HighLevelOfficerWithOnboardingStatus[] | undefined
} & FunctionStatusType

type LoadOfficerType = {
    officer: Officer | undefined
} & FunctionStatusType

type LoadOfficersWithQualificationsType = {
    officers: HighLevelOfficerWithQualifications[]
} & FunctionStatusType

type LoadOfficerCertificationsType = {
    certifications: IssuedCertification[]
} & FunctionStatusType

type LoadOfficerLicensesType = {
    licenses: IssuedLicense[]
} & FunctionStatusType

type LoadOfficerFilesType = {
    files: File[]
} & FunctionStatusType

type LoadOfficerWorkHistoryType = {
    data: OfficerWorkHistory | undefined
} & FunctionStatusType

type LoadOfficerDocumentsType = {
    documents: OnboardingDocumentWithEmployeeAction[]
} & FunctionStatusType

type LoadOfficerReimbursementsType = {
    reimbursements: ReimbursementPolicy[] | undefined
} & FunctionStatusType

type LoadOfficerEarliestTerminationDateType = {
    officer: OfficerEarliestTerminationDate | undefined
} & FunctionStatusType

type LoadOfficerWithPrimaryWorkplaceType = {
    officers: OfficerWithPrimaryWorkplace[] | undefined
} & FunctionStatusType

type LoadOfficersFunc = (onlyTerminated?: boolean) => LoadOfficersType
export const useLoadOfficers: LoadOfficersFunc = (onlyTerminated = false) => {
    const { data, error } = useSWR([`officer`, onlyTerminated], () =>
        OfficerService.readOfficersApiV1OfficerGet(onlyTerminated)
    )
    return {
        officers: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

export const useOfficerMap = (
    officers: HighLevelOfficer[]
): Map<string, HighLevelOfficer> => {
    const allOfficers: Map<string, HighLevelOfficer> = useMemo(() => {
        const result: Map<string, HighLevelOfficer> = new Map()
        officers.forEach((officer) => {
            result.set(officer.id, officer)
        })

        return result
    }, [officers])

    return allOfficers
}

export const useOfficerById = (id: string): HighLevelOfficer | null => {
    const { officers: termedOfficers } = useLoadOfficers(true)
    const { officers } = useLoadOfficers()
    const allOfficersList = useMemo(
        () => officers.concat(termedOfficers),
        [officers, termedOfficers]
    )
    const allOfficerMap = useOfficerMap(allOfficersList)

    return allOfficerMap.get(id) || null
}

export const downloadOfficerExport = async (onlyTerminated?: boolean) => {
    await OfficerService.getOfficerExportApiV1OfficerExportGet(onlyTerminated)
        .then((res) => {
            const blob = new Blob([res], {
                type: 'text/csv',
            })
            const fileURL = window.URL.createObjectURL(blob)
            let alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `employee-status-export.csv`
            alink.click()
        })
        .catch((reason) => toast.error(errorReasonToString(reason)))
}

type LoadOfficersWithQualificationsFunc = (
    shiftInstanceId: string,
    includeNoRegionOfficers?: boolean,
    evaluateAsShiftGroup?: boolean
) => LoadOfficersWithQualificationsType
export const useLoadOfficersWithQualifications: LoadOfficersWithQualificationsFunc =
    (
        shiftInstanceId: string,
        includeNoRegionOfficers: boolean = false,
        evaluateAsShiftGroup: boolean = false
    ) => {
        const { data, error } = useSWR(
            `/officer-with-qualifications/shift-instance/${shiftInstanceId}`,
            () =>
                OfficerService.readOfficersWithQualificationsApiV1OfficerWithQualificationsShiftInstanceIdGet(
                    shiftInstanceId,
                    includeNoRegionOfficers,
                    evaluateAsShiftGroup
                )
        )
        return {
            officers: data ?? [],
            isLoading: !error && !data,
            isError: error,
        }
    }
type LoadOfficersIncBansFunc = (
    onlySchedulable?: boolean
) => LoadOfficersIncBansType
export const useLoadOfficersIncBans: LoadOfficersIncBansFunc = (
    onlySchedulable = false
) => {
    const { data, error } = useSWR([`officer_inc_bans`], () =>
        OfficerService.readOfficersIncBansApiV1OfficerWithBansGet()
    )
    var officers = data ?? []
    if (onlySchedulable) {
        officers = officers.filter(
            (officer) =>
                officer.employee_type ==
                HighLevelOfficerWithBansAndRegions.employee_type.SCHEDULABLE
        )
    }
    return {
        officers: officers,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useSchedulableOfficers = ({
    customerIds,
    regionIds,
}: {
    customerIds: string[]
    regionIds: string[]
}) => {
    const { officers, isLoading } = useLoadOfficersIncBans(true)

    const schedulableNotBannedOfficers = useMemo(() => {
        return {
            isLoading,
            officers: officers.filter(
                isOfficerValidForSiteAndRegion(customerIds, regionIds)
            ),
        }
    }, [officers, isLoading, customerIds, regionIds])

    return schedulableNotBannedOfficers
}

type LoadOfficerWithOnboardingStatusFunc =
    () => LoadOfficerWithOnboardingStatusType
export const useLoadOfficerWithOnboardingStatus: LoadOfficerWithOnboardingStatusFunc =
    () => {
        const { data, error } = useSWR([`/officer/onboarding`], () =>
            OfficerService.readEmployeeForEmployeePortalApiV1OfficerOnboardingGet()
        )
        return {
            officer: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadOfficersWithOnboardingFunc = (
    onlyTerminated?: boolean
) => LoadOfficersWithOnboardingType
export const useLoadOfficersWithOnboarding: LoadOfficersWithOnboardingFunc = (
    onlyTerminated = false
) => {
    const { data, error } = useSWR(
        [`officer-with-onboarding`, onlyTerminated],
        () =>
            OfficerService.readHighLevelEmployeesWithOnboardingApiV1OfficerAllWithOnboardingGet(
                onlyTerminated
            )
    )
    return {
        onboarded: data?.onboarded ?? [],
        onboarding: data?.onboarding ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadSupervisorsFunc = (customerRegionId?: string) => LoadSupervisorsType
export const useLoadSupervisors: LoadSupervisorsFunc = (customerRegionId) => {
    const { data, error } = useSWR(`/supervisor`, () =>
        OfficerService.readSupervisorsApiV1SupervisorsGet(customerRegionId)
    )
    return {
        supervisors: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadOfficerFunc = (id: string) => LoadOfficerType
export const useLoadOfficer: LoadOfficerFunc = (id) => {
    const { data, error } = useSWR(`/officer/${id}`, () =>
        OfficerService.readOfficerApiV1OfficerIdGet(id)
    )
    return {
        officer: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type UpdateOfficerFunc = (
    id: string,
    officer: OfficerUpdate,
    mutate: any
) => Promise<any>
export const updateOfficer: UpdateOfficerFunc = async (id, officer, mutate) => {
    const options = {
        optimisticData: officer,
        rollbackOnError: true,
    }
    toast.info('Saving...', { autoClose: false })
    return mutate(
        `/officer/${id}`,
        async () => {
            const res = await OfficerService.updateOfficerApiV1OfficerIdPut(
                id,
                officer
            )
            toast.dismiss()
            toast.success('Saved')
            return res
        },
        options
    ).catch((error: ApiError) => {
        toast.dismiss()
        officerCollisionToastError(error)
    })
}
type UpdateOfficerCategoryFunc = (
    id: string,
    category: OfficerCategory,
    mutate: any
) => void
export const updateOfficerCategory: UpdateOfficerCategoryFunc = async (
    id,
    category,
    mutate
) => {
    return mutate(`/officer/${id}`, () =>
        OfficerService.updateOfficerCategoryApiV1OfficerCategoryIdPut(
            id,
            category
        )
    ).catch((error: ApiError) => {
        toast.error(errorReasonToString(error))
    })
}

type UpdateOfficerImageFunc = (
    id: string,
    imageUrl: string | undefined,
    mutate: any
) => void
export const updateOfficerImage: UpdateOfficerImageFunc = async (
    id,
    imageUrl,
    mutate
) => {
    const options = {
        rollbackOnError: true,
    }
    return mutate(
        `/officer/${id}`,
        async (existingOfficer: Officer) => {
            await OfficerService.updateOfficerImageApiV1OfficerImageIdPut(
                id,
                imageUrl
            )
            return {
                imageUrl: imageUrl,
                ...existingOfficer,
            }
        },
        options
    ).catch((error: ApiError) => {
        officerCollisionToastError(error)
    })
}
type CreateOfficerFunc = (officer: OfficerCreate) => Promise<Officer>
export const createOfficer: CreateOfficerFunc = (officer) => {
    return OfficerService.createOfficerApiV1OfficerPost(officer)
}

type LoadOfficerCertificationsFunc = (
    id: string
) => LoadOfficerCertificationsType
export const useLoadOfficerCertifications: LoadOfficerCertificationsFunc = (
    id: string
) => {
    const { data, error } = useSWR(`/officer/${id}/certifications`, () =>
        OfficerService.getOfficerCertificationsApiV1OfficerIdCertificationsGet(
            id
        )
    )
    return {
        certifications: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type AddOfficerCertificationFunc = (
    id: string,
    issuedCertificationCreate: IssuedCertificationCreate,
    mutate: any
) => void
export const addOfficerCertification: AddOfficerCertificationFunc = (
    id,
    issuedCertificationCreate,
    mutate
) => {
    mutate(`/officer/${id}/certifications`, () =>
        OfficerService.addOfficerCertificationApiV1OfficerIdCertificationsPut(
            id,
            issuedCertificationCreate
        ).catch((reason) => toast.error(reason.toString()))
    )
}
type EditOfficerCertificationFunc = (
    id: string,
    certification_id: string,
    issuedCertificationUpdate: IssuedCertificationUpdate,
    mutate: any
) => void
export const editOfficerCertification: EditOfficerCertificationFunc = (
    id,
    certification_id,
    issuedCertificationUpdate,
    mutate
) => {
    mutate(`/officer/${id}/certifications`, () =>
        OfficerService.editOfficerCertificationApiV1OfficerIdCertificationsCertificationIdPost(
            id,
            certification_id,
            issuedCertificationUpdate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type DeleteOfficerCertificationFunc = (
    id: string,
    certification_id: string,
    mutate: any
) => void
export const deleteOfficerCertification: DeleteOfficerCertificationFunc = (
    id,
    certification_id,
    mutate
) => {
    mutate(`/officer/${id}/certifications`, () =>
        OfficerService.deleteOfficerCertificationApiV1OfficerIdCertificationsCertificationIdDelete(
            id,
            certification_id
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type TerminateOfficerFunc = (
    id: string,
    termination: TerminateOfficer
) => Promise<any>
export const terminateOfficer: TerminateOfficerFunc = (id, termination) => {
    return OfficerService.terminateOfficerApiV1OfficerTerminateIdDelete(
        id,
        termination
    )
}

type TerminateOfficerOffCycleFinalPayrollFunc = (
    id: string,
    termination: TerminateOfficerOffCycleFinalPayroll
) => Promise<string>

export const terminatedOfficerOffCycleFinalPayrollV2: TerminateOfficerOffCycleFinalPayrollFunc =
    (id, termination) => {
        return OfficerService.afterTerminateOfficerCreateOffCyclePayrunApiV2OfficerTerminateIdOffCyclePayPost(
            id,
            termination
        )
    }

type RestoreOfficerFunc = (id: string, mutate: any) => Promise<any>
export const restoreOfficer: RestoreOfficerFunc = (id, mutate) => {
    return mutate(`/officer/${id}`, () =>
        OfficerService.restoreOfficerApiV1OfficerRestoreIdPut(id)
    ).catch((error: ApiError) => {
        toast.error(`${error?.message}: ${error?.body?.detail}`)
    })
}

//  Issued License related
type LoadOfficerLicensesFunc = (id: string) => LoadOfficerLicensesType
export const useLoadOfficerLicenses: LoadOfficerLicensesFunc = (id: string) => {
    const { data, error } = useSWR(`/officer/${id}/licenses`, () =>
        OfficerService.getOfficerLicensesApiV1OfficerIdLicensesGet(id)
    )
    return {
        licenses: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type AddOfficerLicenseFunc = (
    id: string,
    issuedLicenseCreate: IssuedLicenseCreate,
    mutate: any
) => any
export const addOfficerLicense: AddOfficerLicenseFunc = (
    id,
    issuedLicenseCreate,
    mutate
) => {
    mutate(`/officer/${id}/licenses`, () =>
        OfficerService.addOfficerLicenseApiV1OfficerIdLicensesPut(
            id,
            issuedLicenseCreate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type EditOfficerLicenseFunc = (
    id: string,
    certification_id: string,
    issuedLicenseUpdate: IssuedLicenseUpdate,
    mutate: any
) => any
export const editOfficerLicense: EditOfficerLicenseFunc = (
    id,
    issued_license_id,
    issuedLicenseUpdate,
    mutate
) => {
    mutate(`/officer/${id}/licenses`, () =>
        OfficerService.editOfficerLicenseApiV1OfficerIdLicensesIssuedLicenseIdPost(
            id,
            issued_license_id,
            issuedLicenseUpdate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type DeleteOfficerLicenseFunc = (
    id: string,
    issued_license_id: string,
    mutate: any
) => any
export const deleteOfficerLicense: DeleteOfficerLicenseFunc = (
    id,
    issued_license_id,
    mutate
) => {
    mutate(`/officer/${id}/licenses`, () =>
        OfficerService.deleteOfficerLicenseApiV1OfficerIdLicensesLicenseIdDelete(
            id,
            issued_license_id
        ).catch((reason) => toast.error(reason.toString()))
    )
}

//  Officer file related functions
type LoadOfficerFilesFunc = (id: string) => LoadOfficerFilesType
export const useLoadOfficerFiles: LoadOfficerFilesFunc = (id: string) => {
    const { data, error } = useSWR(`/officer/${id}/files`, () =>
        OfficerService.getOfficerFilesApiV1OfficerIdFilesGet(id)
    )
    return {
        files: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadOfficerDocumentsFunc = (id: string) => LoadOfficerDocumentsType
export const useLoadOfficerDocuments: LoadOfficerDocumentsFunc = (
    id: string
) => {
    const { data, error } = useSWR(`/officer/${id}/documents`, () =>
        OfficerService.getOfficerDocumentsApiV1OfficerIdDocumentsGet(id)
    )
    return {
        documents: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type AddOfficerFilesFunc = (
    id: string,
    fileCreate: FileCreate,
    mutate: any
) => any
export const addOfficerFiles: AddOfficerFilesFunc = (
    id,
    fileCreate,
    mutate
) => {
    mutate(`/officer/${id}/files`, () =>
        OfficerService.addOfficerFileApiV1OfficerIdFilesPost(
            id,
            fileCreate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type EditOfficerObjectFunc = (
    id: string,
    object_id: string,
    FileUpdate: FileUpdate,
    mutate: any
) => any
export const editOfficerFile: EditOfficerObjectFunc = (
    id,
    object_id,
    fileUpdate,
    mutate
) => {
    mutate(`/officer/${id}/files`, () =>
        OfficerService.editOfficerFilesApiV1OfficerIdFilesFileIdPut(
            id,
            object_id,
            fileUpdate
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type DeleteOfficerObjectFunc = (
    id: string,
    object_id: string,
    mutate: any
) => any
export const deleteOfficerFile: DeleteOfficerObjectFunc = (
    id,
    object_id,
    mutate
) => {
    mutate(`/officer/${id}/files`, () =>
        OfficerService.deleteOfficerFileApiV1OfficerIdFilesFileIdDelete(
            id,
            object_id
        ).catch((reason) => toast.error(reason.toString()))
    )
}

export const useGetMineralUsername = (isMineral: boolean) => {
    const { data, error } = useSWR(
        !isMineral ? null : [`/officer/mineral-username`],
        () =>
            OfficerService.readMineralUsernameForBackOfficeUserApiV1OfficerMineralUsernameGet()
    )
    return {
        mineral_username: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const enableOfficerPayroll = async (id: string, mutate: any) => {
    await OfficerService.enableOfficerPayrollApiV1OfficerEnablePayrollIdPost(id)
    mutate(`/officer/${id}`, undefined, true)
}
export const disableOfficerPayroll = async (id: string, mutate: any) => {
    await OfficerService.disableOfficerPayrollApiV1OfficerDisablePayrollIdPost(
        id
    )
    mutate(`/officer/${id}`, undefined, true)
}

type LoadOfficerWorkHistoryFunc = (
    officer_id: string
) => LoadOfficerWorkHistoryType
export const useLoadOfficerWorkHistory: LoadOfficerWorkHistoryFunc = (
    officer_id
) => {
    const { data, error } = useSWR([`work-history`, officer_id], () =>
        OfficerService.getOfficerWorkHistoryApiV1OfficerIdWorkHistoryGet(
            officer_id
        )
    )
    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type LoadOfficerReimbursementsFunc = (
    officer_id: string
) => LoadOfficerReimbursementsType
export const useLoadOfficerReimbursements: LoadOfficerReimbursementsFunc = (
    officer_id
) => {
    const { data, error } = useSWR(
        [`officer/${officer_id}/reimbursements`],
        () =>
            OfficerService.listOfficerReimbursementsApiV1OfficerIdReimbursementsGet(
                officer_id
            )
    )
    return {
        reimbursements: data,
        isLoading: !error && !data,
        isError: error,
    }
}

type UpdateOfficerReimbursementsFunc = (
    officer_id: string,
    payload: ReimbursementPolicyUpdateForOfficer,
    mutate: any
) => Promise<any>
export const updateOfficerReimbursements: UpdateOfficerReimbursementsFunc = (
    officer_id,
    payload,
    mutate
) => {
    return mutate(`/officer/${officer_id}/reimbursements`, () =>
        OfficerService.updateOfficerReimbursementsApiV1OfficerIdReimbursementsPut(
            officer_id,
            payload
        ).catch((reason) => toast.error(reason.toString()))
    )
}

type LoadOfficerEarliestTerminationDateFunc = (
    officer_id: string
) => LoadOfficerEarliestTerminationDateType
export const useLoadOfficerEarliestTerminationDate: LoadOfficerEarliestTerminationDateFunc =
    (officer_id) => {
        const { data, error } = useSWR(
            [`officer_earliest_term`, officer_id],
            () =>
                OfficerService.getOfficerEarliestTermDateApiV1OfficerIdEarliestTermDateGet(
                    officer_id
                )
        )
        return {
            officer: data,
            isLoading: !error && !data,
            isError: error,
        }
    }

type LoadOfficerWithPrimaryWorkplaceFunc =
    () => LoadOfficerWithPrimaryWorkplaceType
export const useLoadOfficerWithPrimaryWorkplace: LoadOfficerWithPrimaryWorkplaceFunc =
    () => {
        const { data, error } = useSWR([`officer_with_primary_workplace`], () =>
            OfficerService.readOfficersIncPrimaryWorkplaceApiV1OfficerWithPrimaryWorkplaceGet()
        )
        return {
            officers: data,
            isLoading: !error && !data,
            isError: error,
        }
    }
