import ChatIcon from '@mui/icons-material/Chat'
import { Badge } from '@mui/material'
import { styled } from '@mui/system'
import { useLoadUserUnreadMessageCount } from 'src/services/chat'

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        fontSize: '0.7em',
    },
}))

export const ChatNavIcon = () => {
    const { unreadCount } = useLoadUserUnreadMessageCount()
    return (
        <StyledBadge
            badgeContent={unreadCount ? unreadCount : null}
            color="error"
            max={99}
        >
            <ChatIcon />
        </StyledBadge>
    )
}
