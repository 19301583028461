/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicOfficer } from '../models/BasicOfficer';
import type { Body_bulk_pay_group_add_api_v1_pay_group_mapping_bulk_add__pay_group_id__post } from '../models/Body_bulk_pay_group_add_api_v1_pay_group_mapping_bulk_add__pay_group_id__post';
import type { OfficerPayGroupStatus } from '../models/OfficerPayGroupStatus';
import type { PayGroup } from '../models/PayGroup';
import type { PayGroupCreate } from '../models/PayGroupCreate';
import type { PayGroupFacilitatorUpdate } from '../models/PayGroupFacilitatorUpdate';
import type { PayGroupMapping } from '../models/PayGroupMapping';
import type { PayGroupMappingCreate } from '../models/PayGroupMappingCreate';
import type { PayGroupWithAssignments } from '../models/PayGroupWithAssignments';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PayGroupService {

    /**
     * Read Pay Groups
     * @returns PayGroup Successful Response
     * @throws ApiError
     */
    public static readPayGroupsApiV1PayGroupsGet(): CancelablePromise<Array<PayGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_groups',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Pay Groups
     * @param requestBody
     * @returns PayGroupWithAssignments Successful Response
     * @throws ApiError
     */
    public static createPayGroupsApiV1PayGroupsPut(
        requestBody: PayGroupCreate,
    ): CancelablePromise<PayGroupWithAssignments> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/pay_groups',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Pay Groups For Officer
     * Returns all of the pay groups that this worker has belonged to.
     * @param officerId
     * @returns PayGroup Successful Response
     * @throws ApiError
     */
    public static readPayGroupsForOfficerApiV1PayGroupsWorkerOfficerIdGet(
        officerId: string,
    ): CancelablePromise<Array<PayGroup>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_groups_worker/{officer_id}',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Pay Group
     * @param payGroupId
     * @returns PayGroupWithAssignments Successful Response
     * @throws ApiError
     */
    public static readPayGroupApiV1PayGroupPayGroupIdGet(
        payGroupId: string,
    ): CancelablePromise<PayGroupWithAssignments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_group/{pay_group_id}',
            path: {
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Default Pay Group
     * @returns BasicOfficer Successful Response
     * @throws ApiError
     */
    public static readDefaultPayGroupApiV1PayGroupDefaultGet(): CancelablePromise<Array<BasicOfficer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_group_default',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Pay Group Mappings For Officer
     * Use to determine if the officer is currently in a pay group without an end date. Use to flag in the UI
     * if a different pay group mapping will change.
     *
     * Also determines the minimum date they can belong to a new pay group, which is the maximum of:
     * - The day after the last day they were paid
     * - The end date of their last pay group mapping
     * - The start date of their last pay group mapping (only if no set end date)
     * @param officerId
     * @returns OfficerPayGroupStatus Successful Response
     * @throws ApiError
     */
    public static getPayGroupMappingsForOfficerApiV1PayGroupStatusOfficerIdGet(
        officerId: string,
    ): CancelablePromise<OfficerPayGroupStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_group_status/{officer_id}',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Pay Groups
     * @param payGroupId
     * @param endDate
     * @returns PayGroupWithAssignments Successful Response
     * @throws ApiError
     */
    public static archivePayGroupsApiV1PayGroupsPayGroupIdDelete(
        payGroupId: string,
        endDate: string,
    ): CancelablePromise<PayGroupWithAssignments> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/pay_groups/{pay_group_id}',
            path: {
                'pay_group_id': payGroupId,
            },
            query: {
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Worker To Pay Group
     * @param requestBody
     * @returns PayGroupMapping Successful Response
     * @throws ApiError
     */
    public static addWorkerToPayGroupApiV1PayGroupMappingPost(
        requestBody: PayGroupMappingCreate,
    ): CancelablePromise<PayGroupMapping> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pay_group_mapping',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Worker From Pay Group
     * @param payGroupId
     * @param officerId
     * @param endDate
     * @returns PayGroupMapping Successful Response
     * @throws ApiError
     */
    public static archiveWorkerFromPayGroupApiV1PayGroupMappingPayGroupIdOfficerIdDelete(
        payGroupId: string,
        officerId: string,
        endDate: string,
    ): CancelablePromise<PayGroupMapping> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/pay_group_mapping/{pay_group_id}/{officer_id}',
            path: {
                'pay_group_id': payGroupId,
                'officer_id': officerId,
            },
            query: {
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bulk Pay Group Add File
     * Returns a file of all active employees for bulk pay group add
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBulkPayGroupAddFileApiV1PayGroupMappingBulkAddEmployeesGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/pay_group_mapping/bulk_add/employees',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Pay Group Add
     * Takes in a csv file of new employees to create pay group mappings for.
     * @param payGroupId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bulkPayGroupAddApiV1PayGroupMappingBulkAddPayGroupIdPost(
        payGroupId: string,
        formData: Body_bulk_pay_group_add_api_v1_pay_group_mapping_bulk_add__pay_group_id__post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pay_group_mapping/bulk_add/{pay_group_id}',
            path: {
                'pay_group_id': payGroupId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Pay Group
     * @param payGroupId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deletePayGroupApiV1PayGroupsPayGroupIdFullDeleteDelete(
        payGroupId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/pay_groups/{pay_group_id}/full_delete',
            path: {
                'pay_group_id': payGroupId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Pay Group Facilitator
     * @param payGroupId
     * @param requestBody
     * @returns PayGroupWithAssignments Successful Response
     * @throws ApiError
     */
    public static addPayGroupFacilitatorApiV1PayGroupsPayGroupIdAdminsPost(
        payGroupId: string,
        requestBody: PayGroupFacilitatorUpdate,
    ): CancelablePromise<PayGroupWithAssignments> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/pay_groups/{pay_group_id}/admins',
            path: {
                'pay_group_id': payGroupId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
