/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Point Model
 */
export type Point = {
    bbox?: (any[] | null);
    type: Point.type;
    coordinates: Array<number>;
};

export namespace Point {

    export enum type {
        POINT = 'Point',
    }


}

