import React from 'react'
import { Customer } from '../../generated'
import {
    styled,
    Button,
    Grid,
    Typography,
    ButtonGroup,
    TextField,
} from '@mui/material'
import { deleteCustomer } from '../../services/customer'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'
import { useUserIsInternal } from '../../utils/auth'

type DeleteModalProps = {
    open: boolean
    handleClose: () => void
    customerName: string | undefined
    customerId: string
}

export const DeleteModal = ({
    open,
    handleClose,
    customerName,
    customerId,
}: DeleteModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [deleteCustomerName, setDeleteCustomerName] =
        React.useState<string>('')
    const [submitting, isSubmitted] = React.useState<boolean>(false)
    const deleteCustomerCallback = React.useCallback(() => {
        isSubmitted(true)
        return deleteCustomer(customerId, mutate)
            .then((value: any) => {
                isSubmitted(false)
                handleClose()
                router.push('/customers')
            })
            .catch((error: ApiError) => {
                toast.error(error?.message)
                isSubmitted(false)
            })
    }, [])

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h2">Delete Customer</Typography>
                        <Typography variant="body1">
                            This will delete {customerName} and all associated
                            data.
                            <br />
                            This action will fail if you have already invoiced
                            this customer.
                            <br />
                            <strong>
                                No record of this customer will remain and this
                                action cannot be undone.
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Please enter {customerName} to confirm deletion:
                        </Typography>
                        <TextField
                            value={deleteCustomerName}
                            onChange={(e) =>
                                setDeleteCustomerName(e.target.value)
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item marginTop={3}>
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="error"
                                disabled={
                                    submitting ||
                                    deleteCustomerName !== customerName
                                }
                                onClick={() => deleteCustomerCallback()}
                            >
                                Delete Customer
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

const CustomerDeleteButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
})

type CustomerDeleteButtonProps = {
    customer?: Customer
    id: string
}
export function CustomerDeleteButton({
    customer,
    id,
}: CustomerDeleteButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const internalUser = useUserIsInternal()

    return (
        <CustomerDeleteButtonContainer>
            {internalUser && (
                <>
                    <DeleteModal
                        open={open}
                        handleClose={handleClose}
                        customerName={customer?.name}
                        customerId={id}
                    />
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleOpen}
                        fullWidth
                    >
                        Delete Customer
                    </Button>
                </>
            )}
        </CustomerDeleteButtonContainer>
    )
}
