/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentTransactionSimple = {
    id: string;
    amount_cents: number;
    fee_amount_cents: number;
    occurred_at: string;
    payment_type: PaymentTransactionSimple.payment_type;
    status: PaymentTransactionSimple.status;
    invoice_number?: string;
    payment_pass_through_fees_enabled?: boolean;
    refunded_at?: string;
    refund_notes?: string;
    chargeback_at?: string;
    chargeback_notes?: string;
    ach_return_at?: string;
    ach_return_notes?: string;
};

export namespace PaymentTransactionSimple {

    export enum payment_type {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }

    export enum status {
        INITIATED = 'Initiated',
        PROCESSING = 'Processing',
        CANCELED = 'Canceled',
        DECLINED = 'Declined',
        ERROR = 'Error',
        FAILED = 'Failed',
        ON_HOLD = 'On Hold',
        PAID = 'Paid',
        DEPOSITED = 'Deposited',
        SETTLED = 'Settled',
        FUNDED = 'Funded',
        REFUNDED = 'Refunded',
    }


}

