/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BreakInstance = {
    started?: string;
    ended?: string;
    officer_start_time?: string;
    officer_end_time?: string;
    break_allotment_id?: string;
    name: string;
    duration_minutes: number;
    is_paid: boolean;
    is_billed: boolean;
    is_unskippable?: boolean;
    hours_worked_per_break: number;
    location_assigned: string;
    officer_id?: string;
    shift_id: string;
    date: string;
    index?: number;
    id: string;
    break_timing_status?: BreakInstance.break_timing_status;
    break_duration_status?: BreakInstance.break_duration_status;
};

export namespace BreakInstance {

    export enum break_timing_status {
        ON_TIME = 'ON_TIME',
        NOT_TAKEN = 'NOT_TAKEN',
        LATE = 'LATE',
        NO_TIMING_REQUIREMENTS = 'NO_TIMING_REQUIREMENTS',
    }

    export enum break_duration_status {
        LESS_THAN_SCHEDULED = 'LESS_THAN_SCHEDULED',
        GREATER_THAN_SCHEDULED = 'GREATER_THAN_SCHEDULED',
        AS_SCHEDULED = 'AS_SCHEDULED',
        N_A = 'N/A',
        LEGACY = 'LEGACY',
    }


}

