/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * LineString Model
 */
export type LineString = {
    bbox?: (any[] | null);
    type: LineString.type;
    coordinates: Array<Array<number>>;
};

export namespace LineString {

    export enum type {
        LINE_STRING = 'LineString',
    }


}

