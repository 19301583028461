/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * MultiLineString Model
 */
export type MultiLineString = {
    bbox?: (any[] | null);
    type: MultiLineString.type;
    coordinates: Array<Array<Array<number>>>;
};

export namespace MultiLineString {

    export enum type {
        MULTI_LINE_STRING = 'MultiLineString',
    }


}

