import React from 'react'
import {
    styled,
    Popover,
    Button,
    Table,
    TableCell,
    TableRow,
    TableBody,
} from '@mui/material'
import {
    CustomerArchiveButton,
    CustomerUnarchiveButton,
} from '../CustomerArchiveButton'
import { CustomerDeleteButton } from './CustomerDeleteButton'
import { Customer } from '../../generated'
import { useRouter } from 'next/router'

const CustomerActionsButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
})

type CustomerActionsButtonContainerProps = {
    customer: Customer | undefined
    id: string
    isAuditLogPrivileged: boolean
}
export function CustomerActionsButton({
    customer,
    id,
    isAuditLogPrivileged,
}: CustomerActionsButtonContainerProps) {
    const router = useRouter()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const openOptions = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseOptions = () => {
        setAnchorEl(null)
    }

    const handleOpenAuditLog = () => {
        handleCloseOptions()
        router.push(`/customers/${id}/auditlog`)
    }
    return (
        <CustomerActionsButtonContainer>
            <Button variant="contained" color="secondary" onClick={handleClick}>
                ...
            </Button>
            <Popover
                open={openOptions}
                anchorEl={anchorEl}
                onClose={handleCloseOptions}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Table padding="none">
                    <TableBody>
                        {customer?.is_archived ? (
                            <>
                                <TableRow>
                                    <TableCell>
                                        <CustomerUnarchiveButton
                                            customer={customer}
                                            id={id}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <CustomerDeleteButton
                                            customer={customer}
                                            id={id}
                                        />
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <TableCell>
                                    <CustomerArchiveButton
                                        customer={customer}
                                        id={id}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {isAuditLogPrivileged ? (
                            <TableRow>
                                <TableCell>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={handleOpenAuditLog}
                                        fullWidth={true}
                                    >
                                        View Audit Log
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ) : null}
                    </TableBody>
                </Table>
            </Popover>
        </CustomerActionsButtonContainer>
    )
}
