/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * MultiPoint Model
 */
export type MultiPoint = {
    bbox?: (any[] | null);
    type: MultiPoint.type;
    coordinates: Array<Array<number>>;
};

export namespace MultiPoint {

    export enum type {
        MULTI_POINT = 'MultiPoint',
    }


}

