/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Polygon Model
 */
export type Polygon = {
    bbox?: (any[] | null);
    type: Polygon.type;
    coordinates: Array<Array<number>>;
};

export namespace Polygon {

    export enum type {
        POLYGON = 'Polygon',
    }


}

