import { PartialRecord } from 'src/types/utilityTypes'

export const TEST_ORG_DEVO = 'org_chAXcStjxla3RcLJ'
export const TEST_ORG_PROD = 'org_7Q6oD3pzP6GL2LyR'
export const DEMO_ONE_ORG = 'org_8En97WOCLMfZo9ik'

const A_AND_A_PROD = 'org_GWiwBQ2EEAutlknG'
const ACE_PRIVATE_SECURITY_PROD = 'org_JIsyMk2n3eHPndaR'
const ARUX_PROD = 'org_OyNrLaDfiBWwwEDD'
const CENTURION_PROD = 'org_3K6eb6Si6AHxaWBd'
const COMMAND_PROD = 'org_Cn4S8saL5Ewh84mL'
const COVEY_PROD = 'org_mOJ5CGATOTE9yagN'
const GREENLIGHT_PROD = 'org_aFChf6LjBBWgjulH'
const HOWE_PROD = 'org_v4OvZS8OPbQJ9YXP'
const INDUSTRY_PROD = 'org_buTf4vSFagAVWnm6'
const JOHNSON_PROD = 'org_dSGm26awaU2PLjQ2'
const KRE_PROD = 'org_2OVCwUtnbqLk8GgO'
const MERCHANTS_PROD = 'org_c9tRW48Sj2BP0arp'
const MPI_PROTECTIVE_PROD = 'org_QWG5DOIZj1mP5Hfd'
const NAIAS_PROD = 'org_oYAeJrXt6Saq0zk0'
const NEXT_LEVEL_PROD = 'org_SqQmj3gIhxo6lgrY'
const NOLA_PROTECTION_PROD = 'org_fXSIRf4wn9o2NOGV'
const N_TACT_PROD = 'org_8QGmqKjWX50qdfZ8'
const OMNIUM_PROD = 'org_brnXOAL9DvjpNGzs'
const OVERWATCH_PROD = 'org_PSAx4UTl6xhdN0s9'
const PHOENIX_PROD = 'org_Cy6BaZlgZgbaNkt5'
const PM_SECURITY_PROD = 'org_rE11G9eNbGCINNxw'
const PRICE_PROTECTIVE_PROD = 'org_0R8g3pukY9BW2Hbj'
const PRO_SECURITY_PROD = 'org_A4Ld3URpGYAhnbND'
const PROFESSIONAL_PROTECTION_PROD = 'org_pqeSTtCaTSq0zGkA'
const SALIENT_DEFENSE_PROD = 'org_CRq1u8mbJPNRMsfy'
const SALIENT_OPERATIONS_PROD = 'org_nKpaAAjWcCJ343oa'
const SHEEPDOG_PROD = 'org_DC6ludzkMN0XXjj7'
const SMR_PROD = 'org_Xdlg4BGAuMf8vmDr'
const STARK_SECURITY_PROD = 'org_jEowaWBJVxs4M7wn'
const STATEWIDE_PROD = 'org_ZLHO5iaUFML9tMiM'
const SUN_CITY_PROD = 'org_CigNDocVahxjMnOe'
const TASK_FORCE_PROD = 'org_uczWp6XdempR6kjw'
const TRESTON_PROD = 'org_xwOshvKdZQ77mRIf'
const TRITON_PROD = 'org_1RUf8aadZx7lSPEn'

const BELFRY_ORGS: string[] = [TEST_ORG_DEVO, TEST_ORG_PROD]
const PAYROLL_V2_ORGS: string[] = [DEMO_ONE_ORG]

const FRIENDLY_ORGS: string[] = [
    COMMAND_PROD,
    COVEY_PROD,
    CENTURION_PROD,
    MPI_PROTECTIVE_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SMR_PROD,
]

const MARKETPLACE_FRIENDLY_ORGS = [
    A_AND_A_PROD,
    CENTURION_PROD,
    COMMAND_PROD,
    COVEY_PROD,
    HOWE_PROD,
    JOHNSON_PROD,
    KRE_PROD,
    MERCHANTS_PROD,
    MPI_PROTECTIVE_PROD,
    NAIAS_PROD,
    NEXT_LEVEL_PROD,
    OMNIUM_PROD,
    OVERWATCH_PROD,
    PHOENIX_PROD,
    PRICE_PROTECTIVE_PROD,
    PRO_SECURITY_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SHEEPDOG_PROD,
    SMR_PROD,
    STARK_SECURITY_PROD,
    SUN_CITY_PROD,
    TRESTON_PROD,
    TRITON_PROD,
]

export enum Features {
    WOTC = 'WOTC',
    PAY_GROUP_FACILITATORS = 'PAY_GROUP_FACILITATORS',
    MASS_ANNOUNCEMENTS = 'MASS_ANNOUNCEMENTS',
    AD_HOC_INV_START_BYPASS = 'AD_HOC_INV_START_BYPASS',
    GROUP_OFFER = 'GROUP_OFFER',
    EMPLOYEE_TIMEOFF = 'EMPLOYEE_TIMEOFF',
    TIME_OFF_SPBS = 'TIME_OFF_SPBS',
    TIMEKEEPING_V2 = 'TIMEKEEPING_V2',
    WORKING_SCHEDULE_V2 = 'WORKING_SCHEDULE_V2',
    REGION_ID_CUSTOMER_CREATE = 'REGION_ID_CUSTOMER_CREATE',
    CUSTOM_INCIDENT_TYPES = 'CUSTOM_INCIDENT_TYPES',
    CHAT = 'CHAT',
    BELLTOWER_REWORK = 'BELLTOWER_REWORK',
    PAYROLL_V2 = 'PAYROLL_V2',
    CONTRACTOR_DEDUCTIONS = 'CONTRACTOR_DEDUCTIONS',
    SHIFT_DRILLDOWN_REWORK = 'SHIFT_DRILLDOWN_REWORK',
}
export type FeatureGate = PartialRecord<Features, string[]>
export const FEATURE_GATE = {
    [Features.WOTC]: [...BELFRY_ORGS, STARK_SECURITY_PROD],
    [Features.PAY_GROUP_FACILITATORS]: [...BELFRY_ORGS],
    [Features.MASS_ANNOUNCEMENTS]: [...BELFRY_ORGS],
    [Features.AD_HOC_INV_START_BYPASS]: [
        ...BELFRY_ORGS,
        SUN_CITY_PROD,
        A_AND_A_PROD,
    ],
    [Features.GROUP_OFFER]: [
        ...BELFRY_ORGS,
        ...MARKETPLACE_FRIENDLY_ORGS,
    ] as string[],
    [Features.EMPLOYEE_TIMEOFF]: [
        ...BELFRY_ORGS,
        ...FRIENDLY_ORGS,
        MERCHANTS_PROD,
        SUN_CITY_PROD,
        SHEEPDOG_PROD,
    ],
    [Features.TIME_OFF_SPBS]: [...BELFRY_ORGS],
    [Features.TIMEKEEPING_V2]: [...BELFRY_ORGS] as string[],
    [Features.WORKING_SCHEDULE_V2]: [...BELFRY_ORGS] as string[],
    [Features.REGION_ID_CUSTOMER_CREATE]: [...BELFRY_ORGS] as string[],
    [Features.CUSTOM_INCIDENT_TYPES]: [...BELFRY_ORGS] as string[],
    [Features.BELLTOWER_REWORK]: [...BELFRY_ORGS, ...FRIENDLY_ORGS] as string[],
    [Features.PAYROLL_V2]: [...PAYROLL_V2_ORGS] as string[],
    [Features.CONTRACTOR_DEDUCTIONS]: [...PAYROLL_V2_ORGS] as string[],
    [Features.CHAT]: [
        ...BELFRY_ORGS,
        ARUX_PROD,
        TASK_FORCE_PROD,
        SALIENT_DEFENSE_PROD,
        SALIENT_OPERATIONS_PROD,
        STATEWIDE_PROD,
        NOLA_PROTECTION_PROD,
        PM_SECURITY_PROD,
        HOWE_PROD,
        CENTURION_PROD,
        COVEY_PROD,
        GREENLIGHT_PROD,
    ] as string[],
    [Features.SHIFT_DRILLDOWN_REWORK]: [...BELFRY_ORGS] as string[],
}

export const INTERNAL_USERS = [
    // 'auth0|62cb212df2c735713aef0f35', // Alex
    'auth0|62eab5b3216efec017dea581', // hello@
    'auth0|6532aed6e475da095b945e65', // hello1@
    'auth0|6573a359bf8a286ca6ff8b3d', // hello2@
    'auth0|65b41d4375425212b4ea1597', // hello3@
    'auth0|661554a7aa70f7f377dc7905', // hello4
    'auth0|669a9029ceb2563e0da12754', // hello5
    'auth0|66db5ab675b7a20957387178', // hello6
]

export const INTERNAL_FIRMS = [
    '01411827-bfe8-4c41-ab44-ec594c4670a8', // BelfryTest
    'a0d9a84e-2c83-4450-8e06-a399f5716099', // Dev
    '87411c28-6965-4a25-bb59-9bd15958850c', // Demo One
    '4e1e458e-155f-450b-aaae-2ebcf613f48e', // Demo Two
]
