/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerCreate } from '../models/CustomerCreate';
import type { CustomerUpdate } from '../models/CustomerUpdate';
import type { File } from '../models/File';
import type { FileCreate } from '../models/FileCreate';
import type { FileUpdate } from '../models/FileUpdate';
import type { HighLevelCustomer } from '../models/HighLevelCustomer';
import type { ScheduleCustomer } from '../models/ScheduleCustomer';
import type { SiteCustomer } from '../models/SiteCustomer';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerService {

    /**
     * Read Customers
     * Return the customers for a users firm.
     * @param onlyArchived
     * @returns HighLevelCustomer Successful Response
     * @throws ApiError
     */
    public static readCustomersApiV1CustomersGet(
        onlyArchived: boolean = false,
    ): CancelablePromise<Array<HighLevelCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers',
            query: {
                'only_archived': onlyArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Schedule Customers
     * Return the customers for a users firm.
     * @param onlyArchived
     * @returns ScheduleCustomer Successful Response
     * @throws ApiError
     */
    public static readScheduleCustomersApiV1CustomersScheduleGet(
        onlyArchived: boolean = false,
    ): CancelablePromise<Array<ScheduleCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/schedule',
            query: {
                'only_archived': onlyArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Site Customers
     * @param includeArchived
     * @returns SiteCustomer Successful Response
     * @throws ApiError
     */
    public static employeeReadSiteCustomersApiV1EmployeeCustomersGet(
        includeArchived: boolean = false,
    ): CancelablePromise<Array<SiteCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/customers',
            query: {
                'include_archived': includeArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Site Customers
     * Return the customers for a patrol .
     * @param includeArchived
     * @param patrolCustomerId
     * @returns SiteCustomer Successful Response
     * @throws ApiError
     */
    public static readSiteCustomersApiV1CustomersSiteGet(
        includeArchived: boolean,
        patrolCustomerId?: (string | null),
    ): CancelablePromise<Array<SiteCustomer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/site',
            query: {
                'include_archived': includeArchived,
                'patrol_customer_id': patrolCustomerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Customer
     * @param id
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static readCustomerApiV1CustomersIdGet(
        id: string,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customers/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customer
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static createCustomerApiV1CustomerPost(
        requestBody: CustomerCreate,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Customer
     * @param id
     * @param requestBody
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static updateCustomerApiV1CustomerIdPut(
        id: string,
        requestBody: CustomerUpdate,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/customer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Customer
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static archiveCustomerApiV1CustomerIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/customer/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Unarchive Customer
     * @param id
     * @returns Customer Successful Response
     * @throws ApiError
     */
    public static unarchiveCustomerApiV1CustomerUnarchiveIdPut(
        id: string,
    ): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/customer/unarchive/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Files
     * @param id
     * @returns File Successful Response
     * @throws ApiError
     */
    public static getCustomerFilesApiV1CustomerIdFilesGet(
        id: string,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/{id}/files',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Customer File
     * @param id
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static addCustomerFileApiV1CustomerIdFilesPost(
        id: string,
        requestBody: FileCreate,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/{id}/files',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Edit Customer Files
     * @param id
     * @param fileId
     * @param requestBody
     * @returns File Successful Response
     * @throws ApiError
     */
    public static editCustomerFilesApiV1CustomerIdFilesFileIdPut(
        id: string,
        fileId: string,
        requestBody: FileUpdate,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/customer/{id}/files/{file_id}',
            path: {
                'id': id,
                'file_id': fileId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Customer File
     * @param id
     * @param fileId
     * @returns File Successful Response
     * @throws ApiError
     */
    public static deleteCustomerFileApiV1CustomerIdFilesFileIdDelete(
        id: string,
        fileId: string,
    ): CancelablePromise<Array<File>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/customer/{id}/files/{file_id}',
            path: {
                'id': id,
                'file_id': fileId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Customer Export
     * @param onlyArchived
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCustomerExportApiV1CustomerExportGet(
        onlyArchived: boolean = false,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/export',
            query: {
                'only_archived': onlyArchived,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Hard Delete Customer
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static hardDeleteCustomerApiV1CustomerIdHardDeleteDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/customer/{id}/hard-delete',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
