/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OffCyclePayrollOptions } from './OffCyclePayrollOptions';

export type PayrollCreate = {
    period_start: string;
    period_end: string;
    payday: string;
    off_cycle_options?: (OffCyclePayrollOptions | null);
    type: PayrollCreate.type;
};

export namespace PayrollCreate {

    export enum type {
        REGULAR = 'regular',
        OFF_CYCLE = 'off_cycle',
        AMENDMENT = 'amendment',
        THIRD_PARTY_SICK_PAY = 'third_party_sick_pay',
    }


}

