/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * MultiPolygon Model
 */
export type MultiPolygon = {
    bbox?: (any[] | null);
    type: MultiPolygon.type;
    coordinates: Array<Array<Array<number>>>;
};

export namespace MultiPolygon {

    export enum type {
        MULTI_POLYGON = 'MultiPolygon',
    }


}

